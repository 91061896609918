import React from 'react';
  
const Projects = () => {
  return (
    <div id ='projects'>
      <h1 id = 'projects-title'>My Projects</h1>
      <div className='info-box'>

        <h2>StatSearcher</h2>
        <div className='code-links'>
          <a href ="https://statsearcher.azurewebsites.net/football/allPlayers"><p id = "site">Live Site</p></a>
          <a href ="https://github.com/mikeu66/Football-app-Django/tree/main/project"><p id = "source">Source Code</p></a>
        </div>

        <h4>Tools used:</h4>
        <div className = 'tools-container'>
          <p class = 'tools'>Django</p>
          <p class = 'tools'>HTML</p>
          <p class = 'tools'>CSS</p>
          <p class = 'tools'>Azure</p>
          <p class = 'tools'>SQLite</p>
          <p class = 'tools'>REST API</p>
          <p class = 'tools'>Pandas</p>
        </div>

      
        <p id = 'description'>
          A project created on the Django framework to pull stats from the espn
          database and display them to the user. The players statistics are also stored in an internal SQLite database to increase
          efficiency and minimize load times. It is hosted on Microsoft Azure
        </p>


      </div>

      <div className='info-box'>

        <h2>MichaelWalter.dev</h2>
        {/* <div className='code-links'>
          <a href ="michaelwalter.dev"><p id = "site">Live Site</p></a>
          <a href ="https://github.com/mikeu66/Football-app-Django/tree/main/project"><p id = "source">Source Code</p></a>
        </div> */}

        <h4>Tools used:</h4>
        <div className = 'tools-container'>
          <p class = 'tools'>ReactJS</p>
          <p class = 'tools'>HTML</p>
          <p class = 'tools'>CSS</p>
          <p class = 'tools'>NodeJS</p>
          <p class = 'tools'>AWS Amplify</p>
        </div>

       
        <p id = 'description'>
          This website was created using ReactJS for the frontend and NodeJS for the backend. It is hosted through AWS Amplify.
        </p>


      
      
      </div>


      <div className='info-box'>

        <h2>DessertDisplayer</h2>
        <div className='code-links'>
          <a href ="https://github.com/mikeu66/DessertDisplayer"><p id = "source">Source Code</p></a>
        </div>

        <h4>Tools used:</h4>
        <div className = 'tools-container'>
          <p class = 'tools'>Swift</p>
          <p class = 'tools'>XCode</p>
          <p class = 'tools'>UIStoryboard</p>
          <p class = 'tools'>REST API</p>
        </div>

       
        <p id = 'description'>
          An iOS app that displays all the desserts listed in TheMealDB API along with the ingredients and instructions for each recipe.
        </p>


      
      
      </div>


      <div className='info-box'>

        <h2>Web Development Course Work</h2>
        <div className='code-links'>
          <a href ='https://github.com/mikeu66/Web-Development'><p id = "source">Github Repo</p></a>
        </div>
        <h4>Tools used:</h4>
        <div className = 'tools-container-webapps'>
          <p class = 'tools'>HTML</p>
          <p class = 'tools'>CSS</p>
          <p class = 'tools'>JavaScript</p>
          <p class = 'tools'>JQuery</p>
          <p class = 'tools'>PHP</p>
          <p class = 'tools'>MySQL</p>
          <p class = 'tools'>Sessions</p>
          <p class = 'tools'>Cookies</p>
        </div>

       
        <p id = 'description'>
          A collection of several of the websites I made in CS 329E Web Programming at the University of Texas.
        </p>


      
      
      </div>


      <div className='info-box'>

        <h2>iOS Course Work</h2>
        <div className='code-links'>
          <a href ='https://github.com/mikeu66/IOS-apps'><p id = "source">Github Repo</p></a>
        </div>

        <h4>Tools used:</h4>
        <div className = 'tools-container-apps'>
          <p class = 'tools'>UIStoryboard</p>
          <p class = 'tools'>SwiftUI</p>
          <p class = 'tools'>Gesture Recognizers</p>
          <p class = 'tools'>Notifications</p>
          <p class = 'tools'>Animations</p>
          <p class = 'tools'>Dispatch Queues</p>
        </div>

        <p id = 'description'>
          A collection of several of the iOS apps I made in CS 329E Mobile Development at the University of Texas.
        </p>


      
      
      </div>

      












      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <br></br>

    </div>
    
  );
};
  
export default Projects;