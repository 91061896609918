import React from 'react';
import { Link } from "react-router-dom";
import resumePDF from "./Michael-Walter-Resume-Current-v4.pdf"
import { ReactComponent as Download } from '../components/icons/down-vote-arrow-icon.svg';

const Resume = () => {
  return (
    <div id = "resume-page">
      <a id = "download-link" href={resumePDF} download = "Michael Walter Resume.pdf"> <Download /> Download </a>

      
      <div id = "resume-sidebar-top">
          <figure id = "languages">
            <Link to = "mailto:mwalter@utexas.edu"> <p>mwalter@utexas.edu</p> </Link>
          
            <figcaption className = "side-captions">Languages</figcaption>
              <ul>
                <li>Python</li>
                <li>Swift</li>
                <li>Java</li>
                <li>Javascript</li>
                <li>HTML</li>
                <li>CSS</li>
                <li>PHP</li>
                <li>SQL</li>
                <li>Processing</li>
              </ul>
            </figure>
          </div>

          <div id = "resume-sidebar-bottom">
            <figure id = "frameworks">
            
            <figcaption className = "side-captions">Technologies/Skills</figcaption>
              <ul>
                <li>Unix</li>
                <li>Django</li>
                <li>ReactJS</li>
                <li>Git</li>
                <li>Rest API</li>
                <li>AWS Amplify</li>
                <li>NodeJS</li>
                <li>Pandas</li>
                <li>Software Testing</li>
                <li>Project Managment</li>
                <li>Graphic Design</li>
              </ul>
            </figure>
          </div>

            

      <div id = "resume-body">
        <h1 id = "resume-title">Michael Walter</h1>
        <h2 id = "resume-work-title">Work Experience</h2>
        
        <figure id = "resume-work">
              <Link to = "https://wonderful.io">
              <figcaption className = "resume-body-captions">Wonderful - Internship</figcaption>
              </Link>
              <p id = "edu-time">Summer 2022</p>

                <ul>
                  <li>Assisted client meetings by documenting desired features and priorities for project manager</li>
                  <li>Performed QA and pre-launch testing on IOS applications and websites communicating findings to developers</li>
                  <li>Created Asana boards to communicate delegated tasks and work flow to developers</li>
                  <li>Assisted front-end developers with debugging </li>
                </ul>
          </figure>


          <h2 id = "resume-edu-title">Education and Certification</h2>
          
          <figure id = "resume-edu">
              
              <figcaption className = "resume-body-captions">University of Texas - Austin</figcaption>
              <p id = "edu-time">Fall 2019 - Spring 2023</p>
              <p className = "subtitle">Elements of Computing Certificate</p>
                <ul>
                  <li>18 Hour Curriculum introducing students to fundamental programming concepts and various languages as well as to creating standalone apps and projects </li>
                  
                </ul>

              <p className = "subtitle">The University of Texas at Austin, Bachelor of Arts</p>
                <ul>
                  <li>Fall, 2022 Ampla Cum Laude (Dean’s honor list)</li>
                  <li>Major: Human Dimensions of Organizations – College of Liberal Arts; Overall GPA: 3.5 </li>
                  <li>Minor: Elements of Computing – Computer Science, College of Natural Sciences; GPA: 3.8</li>
                  
                </ul>
          </figure>


          <h2 id = "resume-courses-title">Relevant Coursework – University of Texas</h2>
          
          <figure id = "resume-edu">
              
             
            
              <p className = "subtitle">CS 303E Elements of Programming (Intro to Python)</p>
                <ul>
                  <li>Worked with object oriented principles</li>
                  <li>Learned about data structures as well as sorting and searching algorithms</li>
                  
                </ul>

              <p className = "subtitle">CS 313E Software Design</p>
                <ul>
                  <li>Learned how to analyze and design reusable software components and compose programs from available components</li>
                  <li>Applied basic data structures and algorithms to software and tested and evaluated programs for their functionality and  performance</li>
                </ul>

              <p className = "subtitle">CS 326E Elements of Networking</p>
                <ul>
                  <li>Created projects using Internet protocols and learned fundamental networking concepts </li>
                </ul>
              
              <p className = "subtitle">CS 329E Mobile Computing</p>
                <ul>
                  <li>Created an assortment of Swift iOS apps in XCode demonstrating knowledge of: SwiftUI, Core data, Gesture Recognizers, Notifications, User Defaults, Animation, Dispatch Queues</li>
                </ul>
              
              <p className = "subtitle">CS 329E Web Programming</p>
                <ul>
                  <li>Developed webpages using HTML, CSS, JavaScript, and PHP </li>
                  <li>Used Unix commands to navigate directories, host webpages on UT server, and edit file permissions </li>
                </ul>
          </figure>

          <div id = "bottom">

            <h2 id = "resume-interests-title">Memberships and Interests</h2>
            <p className = "clubs">Human Dimensions of Organizations Undergraduate Organization (HDOU)</p>
            <p className = "time">Spring 2021 - Spring 2023</p>
          
            <p className = "clubs">Machine Learning and Data Science Club</p>
            <p className = "time">Fall 2022 - Fall 2023</p>

            <p className = "clubs">Interests: </p>

            <p id = "interests">Skiing, Music, Golf, Sports Statistics and Analysis</p>
          </div>
        
      </div>
        
    </div>
  );
};
  
export default Resume;