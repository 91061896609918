import React from 'react';
import { Link } from "react-router-dom";
import {ReactComponent as Github} from '../components/icons/github-icon.svg';
import {ReactComponent as LinkedIn} from '../components/icons/linkedin-square-icon.svg';
import { ReactComponent as Mail } from '../components/icons/envelope-icon.svg';





const Home = () => {
  return (
    <div>
      <Link to="/projects">
        <button id = "project-button">
          <p id = "project-link">My Projects</p>
        </button>

      </Link>
      <div id = "home-body">
        <h1 id = "home-title">Michael Walter</h1>
        <div className="container">
          <h2 id = "home-subtitle"> Software Engineer </h2>
        </div>
      
        
        <div id = "resume-button-container">
          
          <Link to = "resume">
            <button id = "resume-button" className="glow-on-hover" type="button">Resume</button>
          </Link>
        </div>

      </div>
        <div id = "sidebar">
       
          <Link to = "https://github.com/mikeu66">
            <button className = "sidebuttons">
              <Github />
            </button>
            </Link>

          <Link to = "https://www.linkedin.com/in/michael-walter-427a39218/">
            <button className = "sidebuttons">
              <LinkedIn />
            </button>
          </Link>

          <Link to = "mailto:mwalter@utexas.edu">
            <button className = "sidebuttons">
              <Mail />
            </button>
          </Link>
        </div>
      
    </div>
    
  );
};
  
export default Home;