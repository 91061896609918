import React from 'react';
import './App.css';
import Navbar from './components';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './pages';
import Resume from './pages/resume'
import Projects from './pages/projects'

  
function App() {
  
  return (
   
    <Router>
      <Navbar />
      <Routes>
        <Route path='/' element={<Home/> }> </Route>
        <Route path='/resume' element={< Resume/> }> </Route>
        <Route path='/projects' element={< Projects/> }> </Route>
      </Routes>
    </Router>
  );
}
  
export default App;
