import React from 'react';

import { ReactComponent as MWIcon } from './icons/MW-White.svg';


import { Link } from "react-router-dom";


function Navbar(props) {
  return (
    <nav className="navbar">
      <ul className="navbar-nav">{props.children}</ul>
    </nav>
  );
}

  
const WholeNavbar = () => {
  return (
    
    <Navbar>
      <Link to="/" >
          <button id = "home-button">
            <MWIcon className='MWIcon' />
          </button>
      </Link>
    </Navbar>
  );
};
  
export default WholeNavbar;